
import { FaBars,FaTimes, FaGithub, FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from '../images/logo.png';

function Navbar({scrollToSection}) {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };

  return (
    <div className="container">
    <div className="navbar">
      <nav className="main-nav">
        <div className="logo">
        <a href="/">
          <img src={logo} className="logoimg"></img>
          </a>
        </div>
        {/* links */}


        <div className={click ? "menu-link active" : "menu-link "} style={{display:"none"}}>
          <ul>
          <li><Link to='itineraries'>Itineraries</Link></li>
          <li><Link to="/blogs">Blogs</Link></li>
          <li><Link to="/blogs">About</Link></li>
          <li><Link to="/blogs">Login</Link></li>

            <li Link to='/myprofile'></li>
            
          </ul>
          {/* login */}
      
        </div>

        <div style={{display:"none"}} className="hamburger-open-close" onClick={handleClick} >
          {click ? (
            <FaTimes size={30} className="fabars" style={{ color: "white" }} />
          ) : (
            <FaBars size={30}  style={{ color: "black" }} />
          )}
        </div>
      </nav>
    </div>
    </div>
  );
};

export default Navbar






// {

//   const [click, setClick] = useState(false);
 
//   console.log(click);
 
//    const handleClick = () => {
//      setClick(!click);
//      // setClick(false);
//      // console.log(click);
//    };
 
   
//    const handleLinkClick = () => {
//      setClick(false); // Close the navbar when a link is clicked
//    };
 
//    return (
//      <div className="navbar">
    
//        <ul className={click ? "nav-menu active" : "nav-menu"}>
//          <li>
//            <Link to="/"  onClick={()=> handleLinkClick()}>Home</Link>
//            {/* <a  onClick={()=> { scrollToSection('home'); handleLinkClick(); }}  > {'<home />'}</a> */}
//          </li>
//          <li>
           
//            {/* <Link to="#sec2">{'<skills />'}</Link> */}
//            <a onClick={()=> handleLinkClick()}> Trips</a>
//          </li>
//          <li>
//            {/* <Link to="/resume">{'<home />'}</Link> */}
//            <a  onClick={()=> handleLinkClick()}> Blogs</a>
//          </li>
//          <li>
//            {/* <Link to="/contact">{'<home />'}</Link> */}
//            <a  onClick={()=> handleLinkClick()} >About us</a>
//          </li>
//          <li>
//            <a  onClick={()=> handleLinkClick()}> Contact us</a>
//          </li>
//        </ul>
//        <div className="hamburger" onClick={handleClick}>
//          {click ? (
//            <FaTimes size={30} style={{ color: "#ffffff" }} />
//          ) : (
//            <FaBars size={30} style={{ color: "#ffffff" }} />
//          )}
//        </div>
//      </div>  
//    )
//  }