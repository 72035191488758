import React from "react";
import "./LandingPage.css";
import "../Navbar/Navbar.css";
import img1 from "../images/img1.jpg";
import "../Footer/Footer.css"

//import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
// import CardLayout from "../CardLayout/CardLayout";
import About from "../About/About";
import Footer from "../Footer/Footer";
import SeprateCard from "../SeprateCard/SeprateCard";
import playstore from '../../assets/playstoreicon.svg';
import appstore from '../../assets/app store icns.svg';


const LandingPage = () => {
  return (
    <>
      <Navbar />
      <section>
        {/* <div className="split left">
    <div className="centered">
    <h1>Discover <span id="sp1"> the  Best Beautiful Places!</span> </h1>
    <br></br>
    <p id="para1">Lorem ipsum dolor, sit amet consectetur adipisicing elit.
     Veniam dolor nemo animi fugiat non?</p>
    </div>
    
  </div> */}

        {/* <div className=" splitright">
  <div className="center2">
  <img src={img1} alt="images are not allow to see" />
  </div>
  </div> */}

        <div className="maincontainer">
          <div className="leftContainer-header">
            <h2 className="headh1" style={{fontSize:'3rem'}}>
              <strong>
              Craft Your Personalized Itinerary with <span style={{color:'green'}}>TripTraverse</span>
              </strong>
          
            </h2>
         
            <p>
            Every journey is a story. TripTraverse empowers you at every step – from planning your dream vacation to tracking expenses on the road. With intuitive itinerary planning and seamless expense tracking, your journey becomes your story, and we're here to help you tell it.
            </p>
            <div className="appIcons">
              <img src={playstore} alt="" />
              <img src={appstore} alt="" />
            </div>

          </div>

          <div className="rightContainer-header">
          <img src={img1} alt="" className="img1" />
          </div>
        </div>
      </section>

      <br></br>

<br></br>
<br></br>
<SeprateCard/>
<br></br>
<br></br>

{/* <About /> */}
<br></br>
<br></br>
<br></br>

{/* <CardLayout /> */}
<Footer />

</>
);
};

export default LandingPage;
