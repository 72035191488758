import React from "react";
import Itinerary from "../images/Itinerary.jpg";
import blog from "../images/blog.jpg";
import budget from "../images/budget.jpg";

import "./SeprateCard.css";

const SeprateCard = () => {
  return (
    <>
      <div className="container-fluid">
        <center>
          <h3 style={{ textAlign: "center",fontWeight:"bold" }}>What Are Our Trips ?</h3>
          <br />

          <div className="row">
            <div className="col">
              <div className="circle-div">
                <img src={Itinerary} className="card-img-top" alt="..." style={{justifycontent: "center"}}/> 
                <h5 className="card-title">Itinerary</h5>
                <br></br>
                <p className="card-text"> Deeply immersive 5-16 days itineraries with a small group of like-minded people</p>
              </div>
            </div>

        <div className="col">
            <div className="circle-div">
            <img src={Itinerary} className="card-img-top" alt="..." style={{justifycontent: "center"}}/> 
            <h5 className="card-title">Itinerary</h5>
            <br></br>
            <p className="card-text"> Suitable for solo travelers, couples, or a group of friends</p>
            </div>
        </div>


        <div className="col">
            <div className="circle-div">
            <img src={Itinerary} className="card-img-top" alt="..." style={{justifycontent: "center"}}/> 
            <h5 className="card-title">Itinerary</h5>
            <br></br>
            <p className="card-text"> Local insider-led offering authentic experiences difficult to curate independently</p>
            </div>
        </div>

        </div>
        </center>
      </div>

    </>
  );
};
export default SeprateCard;
