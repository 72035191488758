
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import Login from './components/Login/Login';
import LandingPage from './components/LandingPage/LandingPage';

function App() {
  return (
    <>
  <Router>
  <LandingPage />
  </Router>
    </>
  );
}

export default App;
