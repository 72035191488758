import React from "react";


const Footer = () => {
     return(
<>
<div className="container">
<footer className="foot">


<div>
<h1>footer</h1>
</div>

 <div>
    <small>
      Copyright © 2023 Football History Archives. All Rights Reserved.
    </small>

  </div>








</footer>
</div>
</>


)
}



export default Footer